<template id="navBar">
  <nav class="navbar navbar-expand-lg navbar-light fixed-top" style="background-color: #FFFFFF;" v-bind:class=" { 'navbarOpen': show }">
        <div class="container">
            <a class="navbar-brand" href="/">
                <img class="logo img-fluid " src="../assets/images/Logo-01 2.png">
            </a> <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"  aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" @click.stop="toggleNavbar()" >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent"   v-bind:class="{ 'show': show }" >
                <div class=" d-flex align-items-end flex-column flex-md-wrap  justify-content-xl-end" style="width:100%;">
                    <ul class="navbar-nav ms-lg-5 ms-0 text-end px-0 px-md-0 mx-0 py-2 py-md-2 py-lg-0">
                        <li v-if="NamaSales" class="nav-item px-md-1 px-lg-3 py-1 py-md-1 py-lg-0 d-none d-md-none d-lg-block">
                            <a data-bs-toggle="modal" class="btn-wa-headerr" data-bs-target="#exampleModal">
                              <img src="../assets/images/Group 5.png" alt=""></a>
                        </li>
                        <li v-else class="nav-item px-md-1 px-lg-3 py-1 py-md-1 py-lg-0 d-none d-md-none d-lg-block">
                            <a data-bs-toggle="modal" class="btn-wa-headerr" data-bs-target="#exampleModal1">
                              <img src="../assets/images/Group 5.png" alt=""></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
  template: '#navBar',
  props: {
    msg: String
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    toggleNavbar() {
      this.show = !this.show
    }
  },


}
</script>

<style scoped>

@media only screen and (max-width: 1400px) {
    .logo{
            width: 135px;
            height: auto;
            margin-top: -7px;
    }

    .linkNav {
        color: #A76D3C;
        text-decoration: none;
        font-family: Manuale;
        letter-spacing: 0.025em;
        font-size: 17px;

    }
}

@media only screen and (max-width: 700px) {
    .logo{
            width: 105px;
            height: auto;
            margin-top: -7px;
        }

    .active{
    color: #FCC519;
    font-weight: 600;
}

}
.linkNav {
    color: #A76D3C;
    text-decoration: none;
    font-family: Manuale;
    letter-spacing: 0.025em;
    font-size: 20px;

}
.active{
    color: #FCC519;
    font-weight: 600;
}

.logo{
    width : 160px;
    height: auto;
    /* margin-top: -10px; */
    padding: 10px;
    padding-bottom: 10px;
    
}

</style>