<template>
    <section class="container-fluid wa-bottom-mob">
        <div class='row'>
			<!-- <a data-bs-toggle="modal" class="d-flex justify-content-center d-flex justify-content-lg-start d-flex justify-content-md-center btn-wa-footer" data-bs-target="#exampleModal"> -->
            <a id="whatsapp-mobile" data-bs-toggle="modal" data-bs-target="#exampleModal"><div  class="col-md-12 wa-button-mob"  style=" font-family: Montserrat;"><b>WHATSAPP SEKARANG!</b></div></a>
        </div>
    </section>
</template>

<script>

export default {
  name:'ButtonWhatsapp',
  components: {

  },
}
</script>


<style>
.wa-bottom-mob {
	display: none;
}
.wa-button-mob {
	height: 45px;
	width:100%;
	background-color: #0f8815;
	position: fixed;
	z-index: 20;
	bottom: 0;
	left: 0;
	padding: 10px;
	text-align: center;
	font-size: 16px;
	animation-name: ukuranfont; /* Safari 4.0 - 8.0 */
	animation-duration: 1s; /* Safari 4.0 - 8.0 */
	animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
	-o-animation-iteration-count: infinite;
	
}
a .wa-button-mob {
	color: #FFF;
}
@keyframes ukuranfont {
	0%   {font-size: 16px;}
	12.5%   {font-size: 16.5px;}
	25%   {font-size: 17px;}
	37.5%   {font-size: 17.5px;}
	50%   {font-size: 18px;}
	62.5%   {font-size: 17.5px;}
	75%   {font-size: 17px;}
	87.5%   {font-size: 16.5px;}
	100%   {font-size: 16px;}
}
@media screen and (max-width: 768px) {
	.wa-bottom-mob {
	display: block;
	}
}
</style>